import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"

// source: https://www.gatsbyjs.org/docs/adding-a-list-of-markdown-blog-posts/
const GcPostLink = ({ post, imageClasses }) => {
  let featuredImg
  try {
    featuredImg = (
      <Img
        className={`rounded background-transparent mb-1 object-center object-cover ${imageClasses}`}
        fluid={post.frontmatter.featuredImage.childImageSharp.fluid}
      />
    )
  } catch (e) {
    featuredImg = <></>
  }
  return (
    <>
      <Link to={post.frontmatter.path}>{featuredImg}</Link>
      <Link to={post.frontmatter.path}>
        <h2 className="mb-0">{post.frontmatter.title}</h2>
        <h4 className="text-gray-600 mb-2 md:mb-3">{post.frontmatter.date}</h4>
      </Link>
      <p className="">{post.excerpt}</p>
      <Link to={post.frontmatter.path} className="block text-center p-2 border-solid border-2 border-gray-400 bg-white hover:bg-transparent hover:border-gray-700 rounded-full m-1">Continue&nbsp;Reading</Link>
    </>
  )
}

export default GcPostLink
