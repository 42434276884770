import React from "react"
import { graphql } from "gatsby"

import GcLayout from "../components/GcLayout"
import GcPostLink from "../components/GcPostLink"
import SEO from "../components/seo"

const BlogPage = ({
  data: {
    allMdx: { edges },
  },
}) => {
  const Posts = edges
    .filter(edge => !!edge.node.frontmatter.date) // You can filter your posts based on some criteria
    .map(edge => (
      <div className="p-4 shadow hover:bg-gray-100 md:transition duration-500 ease-in-out md:transform hover:-translate-y-1">
        <GcPostLink
          key={edge.node.id}
          post={edge.node}
          imageClasses={
            "h-64 sm:h-40 md:h-32 xl:h-48 w-auto mb-2 md:transition duration-500 ease-in-out md:transform hover:-translate-y-1 hover:skew-x-3 hover:shadow hover:scale-105"
          }
        />
      </div>
    ))
  return (
    <GcLayout>
      <SEO title="Blog" />
      <div className="container mx-auto">
        <div className="mt-2 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-2">
          {Posts}
        </div>
      </div>
    </GcLayout>
  )
}

export default BlogPage

export const pageQuery = graphql`
  query {
    allMdx(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { frontmatter: { path: { glob: "/blog/**" } } }
    ) {
      edges {
        node {
          id
          excerpt(pruneLength: 125)
          frontmatter {
            date(formatString: "DD MMMM, YYYY")
            path
            title
            featuredImage {
              childImageSharp {
                fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`
